/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require("prismjs/themes/prism.css")
require("semantic-ui-less/semantic.less")
require("@fontsource/barlow/400.css")
require("@fontsource/barlow/400-italic.css")
require("@fontsource/barlow/500.css")
require("@fontsource/barlow/600.css")
require("@fontsource/barlow/700.css")
require("./src/less/global.less")
require("./src/styles/global.css")

const FingerprintJS = require("@fingerprintjs/fingerprintjs")

function getCookie(cookieName) {
  return `; ${document.cookie}`.split(`; ${cookieName}=`).pop().split(";")[0]
}

function scrollToElement(hash) {
  const id = window.decodeURI(hash.replace("#", ""))
  if (id !== "") {
    window.scrollTo(0, window.scrollY - 60)
  }
  return null
}

exports.onRouteUpdate = ({ location }) => {
  window.analytics?.page(undefined, undefined, {
    fingerprint: getCookie("fingerprint"),
  })
  scrollToElement(location.hash)
}

exports.onInitialClientRender = () => {
  FingerprintJS.load()
    .then((fp) => fp.get())
    .then((result) => {
      document.cookie = `fingerprint=${result.visitorId}`
    })
}
