exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-embed-newsletter-js": () => import("./../../../src/pages/embed/newsletter.js" /* webpackChunkName: "component---src-pages-embed-newsletter-js" */),
  "component---src-pages-embedded-dashboards-js": () => import("./../../../src/pages/embedded-dashboards.js" /* webpackChunkName: "component---src-pages-embedded-dashboards-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preset-managed-private-cloud-js": () => import("./../../../src/pages/preset-managed-private-cloud.js" /* webpackChunkName: "component---src-pages-preset-managed-private-cloud-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-use-cases-customer-apps-js": () => import("./../../../src/pages/use-cases/customer-apps.js" /* webpackChunkName: "component---src-pages-use-cases-customer-apps-js" */),
  "component---src-pages-use-cases-internal-bi-js": () => import("./../../../src/pages/use-cases/internal-bi.js" /* webpackChunkName: "component---src-pages-use-cases-internal-bi-js" */),
  "component---src-pages-use-cases-internal-tooling-js": () => import("./../../../src/pages/use-cases/internal-tooling.js" /* webpackChunkName: "component---src-pages-use-cases-internal-tooling-js" */),
  "component---src-templates-article-template-index-js": () => import("./../../../src/templates/ArticleTemplate/index.js" /* webpackChunkName: "component---src-templates-article-template-index-js" */),
  "component---src-templates-blog-template-index-js": () => import("./../../../src/templates/BlogTemplate/index.js" /* webpackChunkName: "component---src-templates-blog-template-index-js" */),
  "component---src-templates-event-page-template-index-js": () => import("./../../../src/templates/EventPageTemplate/index.js" /* webpackChunkName: "component---src-templates-event-page-template-index-js" */),
  "component---src-templates-page-template-index-js": () => import("./../../../src/templates/PageTemplate/index.js" /* webpackChunkName: "component---src-templates-page-template-index-js" */)
}

